import React, { useState } from 'react'
import useSWR from 'swr'
import {
	TrafficConditionStatus,
	trafficConditionStatusColors,
	TrafficDesktopProps,
} from 'components/traffic-desktop/traffic-desktop'
import styled, { keyframes } from 'styled-components'
import { SizeMe } from 'react-sizeme'
import { useInView } from 'react-intersection-observer'
import { LoadingCircle } from 'components/loading-circle/loading-circle'
import { SvgPauseSimple } from 'components/svgs/svg-pause-simple'
import { SvgPlaySimple } from 'components/svgs/svg-play-simple'
import { dmsiLiveCondition } from 'utils/dmsi/urls'
import { borderRgbaWithHex } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'
import { notifyError } from 'services/sentry'

type TrafficCondition = {
	status: TrafficConditionStatus
	streets: string[]
	count: number
}

type TrafficMobileProps = TrafficDesktopProps

const TrafficMobileWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	height: 38px;
	background: ${props => props.theme.colors.paleGrey};
`

const TrafficMobileLoading = styled(LoadingCircle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const TrafficMobileIconWrapper = styled.button`
	position: relative;
	width: 38px;
	height: 38px;
	padding: 0;
	${props => borderRgbaWithHex('1px solid', props.theme.colors.twilightBlue, 1)}
	user-select: none;
	background: transparent;

	&:focus {
		outline: none;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`

const slide = keyframes`
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(-50%, 0, 0);
	}
`

const TrafficMobileConditionsWrapper = styled.div`
	width: calc(100% - 38px);
	height: 38px;
	overflow-x: hidden;

	/* Remove scrollbar on IE11 */
	-ms-overflow-style: none;
`

const TrafficMobileConditionsLink = styled.a`
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
`

const TrafficMobileConditions = styled.div<{ innerWidth: number; isPlaying: boolean }>`
	display: inline-flex;
	white-space: nowrap;
	animation: ${slide} ${props => `${(props.innerWidth / 100) * 0.8}s `} linear infinite forwards; /* 100px -> 0.8s */
	animation-play-state: ${props => (props.isPlaying ? 'running' : 'paused')};
	animation-delay: 2s;
`

const TrafficMobileConditionItem = styled.div`
	display: flex;
	flex: 0 0 auto;
`

const TrafficMobileConditionStatusIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 8px;
`

const TrafficMobileConditionStatusIcon = styled.span<{ status: TrafficConditionStatus }>`
	display: block;
	width: 10px;
	height: 10px;
	background-color: ${props => trafficConditionStatusColors[props.status]};
	border-radius: 50%;
`

const TrafficMobileConditionTitle = styled.span`
	padding-top: 4px;
	margin-right: 16px;
	font-size: 14px;
	font-weight: 700;
	line-height: 34px;
	user-select: none;
`

const TrafficMobileConditionStreets = styled.div`
	display: flex;
`

const TrafficMobileConditionStreet = styled.span`
	padding-top: 4px;
	margin-right: 12px;
	font-size: 14px;
	font-weight: 500;
	line-height: 34px;
	user-select: none;
`

const TrafficMobileComponent = ({ link }: TrafficMobileProps) => {
	const [isPlaying, setIsPlaying] = useState(true)

	const { data, error } = useSWR('/api/dmsi-live-condition', dmsiLiveCondition)
	const [ref, inView] = useInView({ skip: !data || !!error })

	const conditionStreets = (streets: string[]) => {
		return streets.map((street, streetIndex) => (
			<TrafficMobileConditionStreet key={streetIndex}>{street}</TrafficMobileConditionStreet>
		))
	}

	const conditionList = (conditions: TrafficCondition[]) => {
		// duplicate the conditions array for the infinite slider effect
		const slideConditions = [...conditions, ...conditions]
		return slideConditions.map((condition, index) => (
			<TrafficMobileConditionItem key={index}>
				<TrafficMobileConditionStatusIconWrapper>
					<TrafficMobileConditionStatusIcon status={condition.status} />
				</TrafficMobileConditionStatusIconWrapper>
				<TrafficMobileConditionTitle>Trânsito {condition.status.toLowerCase()}</TrafficMobileConditionTitle>
				<TrafficMobileConditionStreets>{conditionStreets(condition.streets.slice(0, 3))}</TrafficMobileConditionStreets>
			</TrafficMobileConditionItem>
		))
	}

	if (error) {
		notifyError(error)
		return null
	}

	if (!data) {
		return (
			<TrafficMobileWrapper>
				<TrafficMobileLoading diameter={20} strokeWidth={3} color="twilightBlue" />
			</TrafficMobileWrapper>
		)
	}

	return (
		<TrafficMobileWrapper ref={ref}>
			<TrafficMobileIconWrapper onClick={() => setIsPlaying(!isPlaying)}>
				{isPlaying ? <SvgPauseSimple suffix="-traffic-mobile" /> : <SvgPlaySimple suffix="-traffic-mobile" />}
			</TrafficMobileIconWrapper>
			<TrafficMobileConditionsWrapper>
				<SizeMe>
					{({ size }) => (
						<NextLink href={link.href} passHref>
							<TrafficMobileConditionsLink>
								{/* pass value 0 to innerWidth to prevent SSR issues */}
								<TrafficMobileConditions innerWidth={size.width || 0} isPlaying={isPlaying && inView}>
									{conditionList(data)}
								</TrafficMobileConditions>
							</TrafficMobileConditionsLink>
						</NextLink>
					)}
				</SizeMe>
			</TrafficMobileConditionsWrapper>
		</TrafficMobileWrapper>
	)
}

export const TrafficMobile = React.memo(TrafficMobileComponent)
