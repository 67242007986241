import React from 'react'
import styled from 'styled-components'
import { SvgPlaySmall } from 'components/svgs/svg-play-small'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { Tag } from 'components/tag/tag'
import { Title } from 'components/title/title'
import { DateText } from 'components/date-text/date-text'
import { rgbaWithHex } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'

const defaultProps = {
	description: '',
}
type NewsCardVideoMobileProps = {
	id: string | number
	url: string
	title: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
} & typeof defaultProps

const NewsCardVideoMobileWrapper = styled.article`
	display: flex;
	padding: 8px 0 12px;
`

const NewsCardVideoMobileThumbnail = styled.div`
	position: relative;
	flex: 0 0 70px;
	height: 70px;
	margin-top: 4px;
`

const NewsCardVideoMobileLink = styled.a`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

const NewsCardVideoMobileIconWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	${props => rgbaWithHex(props.theme.colors.black, 0.5, 'background')}
`

const NewsCardVideoMobileIcon = styled.a`
	padding: 4px;
	pointer-events: all;
`

const NewsCardVideoMobileBody = styled.div`
	flex-grow: 1;
	padding-left: 8px;
`

const NewsCardVideoMobileHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-top: 1px;
`

const NewsCardVideoMobileDateText = styled.div`
	padding-top: 4px;
`

const NewsCardVideoMobileTitleLink = styled.a`
	&:hover {
		text-decoration: none;
	}
`

const NewsCardVideoMobileTitle = styled(Title)`
	margin-top: 4px;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;

	&:hover {
		color: ${props => props.theme.text.secondary};
	}
`

const NewsCardVideoMobileComponent = ({ id, url, thumbnail, title, category, timestamp }: NewsCardVideoMobileProps) => {
	return (
		<NewsCardVideoMobileWrapper>
			<NewsCardVideoMobileThumbnail>
				<NextLink href={url} passHref>
					<NewsCardVideoMobileLink>
						<LazyImage {...thumbnail} aspectRatio="70/70" />
					</NewsCardVideoMobileLink>
				</NextLink>
				<NewsCardVideoMobileIconWrapper>
					<NextLink href={url} passHref>
						<NewsCardVideoMobileIcon>
							<SvgPlaySmall suffix={`-${id}`} />
						</NewsCardVideoMobileIcon>
					</NextLink>
				</NewsCardVideoMobileIconWrapper>
			</NewsCardVideoMobileThumbnail>
			<NewsCardVideoMobileBody>
				<NewsCardVideoMobileHeader>
					<Tag href={category.href} transparent>
						{category.label}
					</Tag>
					<NewsCardVideoMobileDateText>
						<DateText timestamp={timestamp} />
					</NewsCardVideoMobileDateText>
				</NewsCardVideoMobileHeader>
				<NextLink href={url} passHref>
					<NewsCardVideoMobileTitleLink>
						<NewsCardVideoMobileTitle size={3} maxLines={2}>
							{title}
						</NewsCardVideoMobileTitle>
					</NewsCardVideoMobileTitleLink>
				</NextLink>
			</NewsCardVideoMobileBody>
		</NewsCardVideoMobileWrapper>
	)
}

NewsCardVideoMobileComponent.defaultProps = defaultProps

export const NewsCardVideoMobile = React.memo(NewsCardVideoMobileComponent)
