import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { darkTheme } from 'theme/theme'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { Title } from 'components/title/title'
import { SectionLayout } from 'components/section-layout/section-layout'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

type HomepageLayoutProps = {
	title: PropTypes.ReactNodeLike
	highlightedTopics: PropTypes.ReactNodeLike[]
	hasTraffic: boolean
	trafficDesktop?: PropTypes.ReactNodeLike
	trafficMobile?: PropTypes.ReactNodeLike
	news: PropTypes.ReactNodeLike[]
	newsLink: PropTypes.ReactNodeLike
	mostRead: PropTypes.ReactNodeLike
	verticalAdvertisement: PropTypes.ReactNodeLike
	nextEvents: PropTypes.ReactNodeLike
	videoTitle: PropTypes.ReactNodeLike
	videoNews: PropTypes.ReactNodeLike[]
	videoLink: PropTypes.ReactNodeLike
	photoGalleryTitle: PropTypes.ReactNodeLike
	photoGalleryNews: PropTypes.ReactNodeLike[]
	photoGalleryLink: PropTypes.ReactNodeLike
	horizontalAdvertisement: PropTypes.ReactNodeLike
	categories: PropTypes.ReactNodeLike[]
	head: PropTypes.ReactNodeLike
}

const HomePageTitle = styled(Title)`
	margin: 29px 0 20px;
	font-weight: 500;
	line-height: 40px;

	b,
	strong {
		font-weight: 700;
	}
`

const HighlightedTopic = styled.div`
	/* Fix IE11 overflow issue */
	flex: 1;
`

const HighlightedTopicsWrapper = styled.div`
	background: ${props => props.theme.colors.paleGrey};
`

const HomePageHorizontalAd = styled(SectionLayout)`
	padding-top: 38px;
	padding-bottom: 31px;

	${mediaBreakpointUpLg} {
		padding-top: 64px;
		padding-bottom: 63px;
	}
`

const HomePageExtraCategories = styled(SectionLayout)`
	padding-top: 0;
	padding-bottom: 31px;

	${mediaBreakpointUpLg} {
		padding-bottom: 73px;
	}
`

export const HomepageLayout = ({
	title,
	highlightedTopics,
	hasTraffic,
	trafficDesktop,
	trafficMobile,
	news,
	newsLink,
	mostRead,
	verticalAdvertisement,
	nextEvents,
	videoTitle,
	videoNews,
	videoLink,
	photoGalleryTitle,
	photoGalleryNews,
	photoGalleryLink,
	horizontalAdvertisement,
	categories,
	head,
}: HomepageLayoutProps) => {
	const { isMobile } = useMediaQueryContext()
	const highlightedNews = news.slice(0, 3)
	const remainingNews = news.slice(3)
	const highlightedPhotoGalleries = photoGalleryNews.slice(0, 3)
	const remainingPhotoGalleries = photoGalleryNews.slice(3)
	const extraCategories = categories.slice(0, 4)

	return (
		<>
			{head}
			{/* homepage title - desktop */}
			{!isMobile && (
				<div className="container">
					<div className="row">
						<div className="col">
							<HomePageTitle size={1} dangerHtml>
								{title}
							</HomePageTitle>
						</div>
					</div>
				</div>
			)}
			{/* traffic - mobile */}
			{isMobile && hasTraffic && (
				<SectionLayout noPaddingTop noPaddingBottom>
					{trafficMobile}
				</SectionLayout>
			)}
			{/* highlighted topics & traffic - desktop */}
			{!isMobile && (
				<SectionLayout noPaddingTop noPaddingBottom>
					<div className="container">
						<div className="row">
							<div className={[hasTraffic ? 'col-8 pt-12' : 'col-12', 'mb-24'].join(' ')}>
								<div className="row">
									{highlightedTopics.length === 3 && hasTraffic ? (
										<div className="col d-flex">
											{highlightedTopics.map((topic, index) => (
												<HighlightedTopic className={index > 0 ? 'ml-12' : undefined} key={index}>
													{topic}
												</HighlightedTopic>
											))}
										</div>
									) : (
										highlightedTopics.map((topic, index) => (
											<div className="col" key={index}>
												{topic}
											</div>
										))
									)}
								</div>
							</div>
							{hasTraffic && (
								<div className="col-4 mb-24">
									<div style={{ background: '#eceff5', height: '107px' }}>{trafficDesktop}</div>
								</div>
							)}
						</div>
					</div>
				</SectionLayout>
			)}
			{/* simple news section */}
			<SectionLayout className="mt-only-sm-16 mt-only-md-16" noPaddingTop>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0 highlighted">{highlightedNews[0]}</div>
						<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
							<div className="row">
								<div className="col-12 highlighted">{highlightedNews[1]}</div>
								<div className="col-12 highlighted">{highlightedNews[2]}</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">
							<div className="row">
								{remainingNews.map((newsItem, index) => (
									<div key={index} className="col-lg-6 mb-35">
										{newsItem}
									</div>
								))}
								<div className="col-12 mb-xs-31 mt-3">
									<div>{newsLink}</div>
								</div>
							</div>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
							<HighlightedTopicsWrapper className="row">
								{isMobile &&
									highlightedTopics.map((topic, index) => (
										<div
											key={index}
											className={[
												'col-12',
												index === 0 ? 'mt-24' : '',
												index < highlightedTopics.length - 1 ? 'mb-15' : 'mb-33',
											].join(' ')}>
											{topic}
										</div>
									))}
							</HighlightedTopicsWrapper>
							<div className="row">
								<div className="col-12 mt-xs-20 mb-xs-21 mb-30">{mostRead}</div>
								{verticalAdvertisement && <div className="col-12 mb-xs-21 mb-30">{verticalAdvertisement}</div>}
								<div className="col-12">{nextEvents}</div>
							</div>
						</div>
					</div>
				</div>
			</SectionLayout>
			{/* videos section */}
			<ThemeProvider theme={darkTheme}>
				<SectionLayout lazy lazyHeights={{ xs: 800, sm: 1182, md: 1295, lg: 630, xl: 720 }}>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-24 mb-33">{videoTitle}</div>
							<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0 highlighted">{videoNews[0]}</div>
							<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
								<div className="row">
									<div className="col-12 highlighted">{videoNews[1]}</div>
									<div className="col-12 highlighted">{videoNews[2]}</div>
								</div>
							</div>
							{isMobile && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mt-xs-23">
									<div>{videoLink}</div>
								</div>
							)}
						</div>
					</div>
				</SectionLayout>
			</ThemeProvider>
			{/* photo galleries section */}
			<SectionLayout lazy lazyHeights={{ xs: 1840, sm: 2450, md: 2700, lg: 950, xl: 1050 }}>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-24 mb-33">{photoGalleryTitle}</div>
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0 highlighted">{highlightedPhotoGalleries[0]}</div>
						<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
							<div className="row">
								<div className="col-12 highlighted">{highlightedPhotoGalleries[1]}</div>
								<div className="col-12 highlighted">{highlightedPhotoGalleries[2]}</div>
							</div>
						</div>
						{remainingPhotoGalleries.map((photoGallery, index) => (
							<div key={index} className="col-md-10 offset-md-1 col-lg-4 offset-lg-0 mb-xs-24">
								{photoGallery}
							</div>
						))}
						{isMobile && (
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mt-xs-12">
								<div>{photoGalleryLink}</div>
							</div>
						)}
					</div>
				</div>
			</SectionLayout>
			{/* horizontal advertisement section */}
			{horizontalAdvertisement && (
				<HomePageHorizontalAd lazy lazyHeights={{ xs: 230, lg: 250, xl: 300 }}>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
						</div>
					</div>
				</HomePageHorizontalAd>
			)}
			{/* extra categories section */}
			<HomePageExtraCategories lazy lazyHeights={{ xs: 1450, md: 950, lg: 650, xl: 630 }}>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<div className="row">
								{extraCategories.map((categoryItem, index) => (
									<div key={index} className={`col-md-6 col-lg-3 ${extraCategories[index + 1] ? 'mb-xs-17' : ''}`}>
										{categoryItem}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</HomePageExtraCategories>
		</>
	)
}
