import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

const SvgLogoPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgPlaySimple: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-simple' + suffix

	return (
		<svg width={24} height={24} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgLogoPath
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.116 13.352l-10.092 6.21A1.001 1.001 0 016.5 18.71V6.29a1 1 0 011.524-.852l10.092 6.21a1.001 1.001 0 010 1.704z"
				fill="#fff"
			/>
		</svg>
	)
}
