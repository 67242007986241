import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Pausa',
	[Language.En]: 'Pause',
}

const SvgLogoPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgPauseSimple: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-pause-simple' + suffix

	return (
		<svg width={24} height={24} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgLogoPath d="M10 6H8a1 1 0 00-1 1v11a1 1 0 001 1h2a1 1 0 001-1V7a1 1 0 00-1-1zm6 0h-2a1 1 0 00-1 1v11a1 1 0 001 1h2a1 1 0 001-1V7a1 1 0 00-1-1z" />
		</svg>
	)
}
