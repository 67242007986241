import React from 'react'
import styled from 'styled-components'

import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { boxShadowRgbaWithHex } from 'theme/utils'
import { MostReadItem, MostReadItemRow } from 'components/most-read/most-read'
import { Title } from 'components/title/title'
import { LazyImage } from 'components/lazy-image/lazy-image'

type HighlightedColumnProps = {
	title: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	items: MostReadItem[]
}

const HighlightedColumnTitle = styled(Title)`
	padding: 0 0 5px;
	margin-bottom: 1px;
	font-size: 28px;
	color: ${props => props.theme.colors.twilightBlue};
	border-bottom: 1px solid ${props => props.theme.colors.lightPeriwinkle};

	${mediaBreakpointUpLg} {
		padding: 0;
		margin-bottom: 3px;
		font-size: 20px;
		color: ${props => props.theme.colors.black};
		text-transform: uppercase;
		border-bottom: 0;
	}
`

const HighlightedColumnThumbnail = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	height: 143px;
	margin-bottom: 1px;
	background-color: ${props => props.theme.colors.twilightBlue};
	border-radius: 2px;
	${props => boxShadowRgbaWithHex('0 1px 10px 0', props.theme.colors.black, 0.1)}
`

const HighlightedColumnIconWrapper = styled.div`
	width: 100%;
	max-width: 88px;
	height: auto;
	max-height: 88px;
	margin-right: 16px;
	margin-bottom: 16px;
`

const HighlightedColumnComponent = ({ title, thumbnail, items }: HighlightedColumnProps) => {
	const { isMobile } = useMediaQueryContext()

	return (
		<div>
			<HighlightedColumnTitle size={2}>{title}</HighlightedColumnTitle>

			{!isMobile && (
				<HighlightedColumnThumbnail>
					<HighlightedColumnIconWrapper>
						<LazyImage {...thumbnail} aspectRatio="88/88" />
					</HighlightedColumnIconWrapper>
				</HighlightedColumnThumbnail>
			)}

			<div>{items.map((item, index) => MostReadItemRow(item, index, false))}</div>
		</div>
	)
}

export const HighlightedColumn = React.memo(HighlightedColumnComponent)
