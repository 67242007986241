import React from 'react'
import { min, max, parseISO, formatISO } from 'date-fns'
import { NextSeo } from 'next-seo'
import useSWR from 'swr'
import { HomePageDataFragment } from 'generated/sdk'
import { Featured, FeaturedSizes } from 'components/featured/featured'
import { NewsCardFeatured } from 'components/news-card-featured/news-card-featured'
import { NewsCard, NewsCardSizes, NewsCardTypes } from 'components/news-card/news-card'
import { categoryPageDataMapper, getRenditionUrl, topicDataMapper } from 'utils/data'
import { NewsCardVideoMobile } from 'components/news-card-video-mobile/news-card-video-mobile'
import { HighlightedColumn } from 'components/highlighted-column/highlighted-column'
import { HomepageLayout } from 'components/layouts/homepage-layout/homepage-layout'
import { Advertisement, OrientationTypes } from 'components/advertisement/advertisement'
import { NextEvents } from 'components/next-events/next-events'
import { SectionTitle } from 'components/section-title/section-title'
import { MostRead } from 'components/most-read/most-read'
import { PrimaryButton } from 'components/primary-button/primary-button'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { TrafficMobile } from 'components/traffic-mobile/traffic-mobile'
import { TrafficDesktop } from 'components/traffic-desktop/traffic-desktop'
import { dmsiLiveCondition } from 'utils/dmsi/urls'

type HomepageProps = {
	pageData: HomePageDataFragment
}

const Homepage = ({ pageData }: HomepageProps) => {
	const { isMobile } = useMediaQueryContext()
	const { language } = useLocationContext()
	const seoData = useSeoContext()

	const { error: trafficError } = useSWR('/api/dmsi-live-condition', dmsiLiveCondition)

	const hasTraffic = language === 'pt'
	const highlightedTopicsLength = pageData.highlightedTopics.length
	const size = isMobile
		? FeaturedSizes.SMALL
		: hasTraffic
		? highlightedTopicsLength < 3
			? FeaturedSizes.MEDIUM
			: FeaturedSizes.SMALL
		: highlightedTopicsLength < 3
		? FeaturedSizes.LARGE
		: FeaturedSizes.MEDIUM

	const highlightedTopics = pageData.highlightedTopics.map(({ id, topic, page }) => {
		const thumbnailMapper = {
			[FeaturedSizes.SMALL]: {
				src: getRenditionUrl(topic.thumbnail.small),
				srcRetina: getRenditionUrl(topic.thumbnail.medium),
			},
			[FeaturedSizes.MEDIUM]: {
				src: getRenditionUrl(topic.thumbnail.medium),
				srcRetina: getRenditionUrl(topic.thumbnail.large),
			},
			[FeaturedSizes.LARGE]: {
				src: getRenditionUrl(topic.thumbnail.large),
				srcRetina: getRenditionUrl(topic.thumbnail.extraLarge),
			},
		}
		const props = {
			...topicDataMapper(topic),
			url: page?.url || '#',
			thumbnail: {
				src: thumbnailMapper[size].src,
				srcRetina: thumbnailMapper[size].srcRetina,
				srcPlaceholder: topic.thumbnail.placeholderHash,
				alt: topic.thumbnail.title,
			},
			size,
		}
		return <Featured key={id} {...props} />
	})

	const trafficLink = {
		href: pageData.trafficLinkPage?.url || 'https://transito.cm-porto.pt/',
		label: pageData.trafficLinkText || '',
	}
	const trafficMobile = hasTraffic && isMobile ? <TrafficMobile link={trafficLink} /> : null
	const trafficDesktop = hasTraffic && !isMobile ? <TrafficDesktop link={trafficLink} /> : null

	const news = pageData.articlesSimple.map((article, index) => {
		const { id, title, description, url, thumbnail, categoryPage, timestamp } = article
		const isLarge = index === 0
		const isHighlighted = index < 3
		const props = {
			key: id,
			id: id || index,
			url,
			title,
			category: categoryPageDataMapper(categoryPage),
			...(!isHighlighted && { description }),
			timestamp: timestamp || '',
			thumbnail: {
				src: getRenditionUrl(isLarge && !isMobile ? thumbnail.medium : thumbnail.small),
				srcRetina: getRenditionUrl(isLarge && !isMobile ? thumbnail.large : thumbnail.medium),
				srcPlaceholder: thumbnail.placeholderHash,
				alt: thumbnail.title,
				loadEagerly: isHighlighted,
			},
			type: article.sliderItems.length > 0 ? NewsCardTypes.photoGallery : NewsCardTypes.simple,
		}

		if (isHighlighted) {
			return <NewsCardFeatured {...props} {...(isLarge && { size: NewsCardSizes.large })} />
		} else {
			return <NewsCard {...props} />
		}
	})

	const mostReadItems = pageData.mostReadArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const verticalAdvertisementItems = pageData.advertisementsVertical.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.small),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.medium),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const nextEventsItems = pageData.nextEvents.map(({ url, title, dates, locations }) => {
		const startDates = dates.map(date => parseISO(date.start))
		const endDates = dates.map(date => parseISO(date.end))

		return {
			url,
			title,
			startDate: formatISO(min(startDates)),
			endDate: formatISO(max(endDates)),
			address: locations[0].location.address || '',
		}
	})

	const nextEventsLink = {
		label: pageData.nextEventsLinkText,
		href: pageData.nextEventsLinkPage?.url || '#',
	}

	const videoLink = {
		label: pageData.videosLinkText,
		href: pageData.videosLinkPage?.url || '#',
	}

	const videoNews = pageData.videos.map((article, index) => {
		const { id, title, url, thumbnail, categoryPage, timestamp } = article
		const isLarge = index === 0
		const isHighlighted = index < 3
		const props = {
			key: id,
			id: id || index,
			url,
			title,
			category: categoryPageDataMapper(categoryPage),
			timestamp: timestamp || '',
			thumbnail: {
				src: getRenditionUrl(isLarge && !isMobile ? thumbnail.medium : thumbnail.small),
				srcRetina: getRenditionUrl(isLarge && !isMobile ? thumbnail.large : thumbnail.medium),
				srcPlaceholder: thumbnail.placeholderHash,
				alt: thumbnail.title,
			},
		}

		if (isHighlighted) {
			return <NewsCardFeatured {...props} {...(isLarge && { size: NewsCardSizes.large })} type={NewsCardTypes.video} />
		} else if (isMobile) {
			return <NewsCardVideoMobile {...props} />
		} else {
			return <NewsCard {...props} type={NewsCardTypes.video} />
		}
	})

	const photoGalleryLink = {
		label: pageData.photoGalleriesLinkText,
		href: pageData.photoGalleriesLinkPage?.url || '#',
	}

	const photoGalleryNews = pageData.photoGalleries.map((article, index) => {
		const { id, title, url, thumbnail, categoryPage, timestamp } = article
		const isLarge = index === 0
		const isHighlighted = index < 3
		const props = {
			key: id,
			id: id || index,
			url,
			title,
			category: categoryPageDataMapper(categoryPage),
			timestamp: timestamp || '',
			thumbnail: {
				src: getRenditionUrl(isLarge && !isMobile ? thumbnail.medium : thumbnail.small),
				srcRetina: getRenditionUrl(isLarge && !isMobile ? thumbnail.large : thumbnail.medium),
				srcPlaceholder: thumbnail.placeholderHash,
				alt: thumbnail.title,
			},
		}

		if (isHighlighted) {
			return (
				<NewsCardFeatured
					{...props}
					{...(isLarge && { size: NewsCardSizes.large })}
					type={NewsCardTypes.photoGallery}
				/>
			)
		} else {
			return <NewsCard {...props} type={NewsCardTypes.photoGallery} />
		}
	})

	const horizontalAdvertisementItems = pageData.advertisementsHorizontal.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.large),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.extraLarge),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const extraCategories = pageData.extraCategories.map(extraCategory => {
		const title = extraCategory.category.name
		const mappedLatestArticles = extraCategory.articles.map(({ __typename, id, ...article }) => ({
			...article,
			category: categoryPageDataMapper(article.categoryPage),
		}))
		const thumbnail = {
			src: getRenditionUrl(extraCategory.thumbnail.small),
			srcRetina: getRenditionUrl(extraCategory.thumbnail.medium),
			srcPlaceholder: '',
			alt: extraCategory.thumbnail.title,
		}

		return (
			<HighlightedColumn
				key={extraCategory.category.id}
				title={title}
				thumbnail={thumbnail}
				items={mappedLatestArticles}
			/>
		)
	})

	const tags = pageData.tags.length > 0 ? pageData.tags : seoData.tags

	return (
		<HomepageLayout
			title={pageData.customPageTitle.replace('<p>', '').replace('</p>', '')}
			highlightedTopics={highlightedTopics}
			hasTraffic={hasTraffic && !trafficError}
			trafficMobile={trafficMobile}
			trafficDesktop={trafficDesktop}
			news={news}
			newsLink={
				<PrimaryButton
					suffix="homepage-news"
					href={`${pageData.articlesLinkPage?.url || '#'}?before=${
						pageData.articlesSimple[pageData.articlesSimple.length - 1].id || ''
					}`}>
					{pageData.articlesLinkText}
				</PrimaryButton>
			}
			mostRead={<MostRead title={pageData.mostReadTitle} items={mostReadItems} />}
			verticalAdvertisement={
				verticalAdvertisementItems.length > 0 && (
					<Advertisement items={verticalAdvertisementItems} orientation={OrientationTypes.vertical} />
				)
			}
			nextEvents={<NextEvents items={nextEventsItems} link={nextEventsLink} title={pageData.nextEventsTitle} />}
			videoTitle={
				<SectionTitle suffix="homepage-videos-title" link={videoLink}>
					{pageData.videosTitle}
				</SectionTitle>
			}
			videoNews={videoNews}
			videoLink={
				<PrimaryButton suffix="homepage-videos-link" href={videoLink.href}>
					{videoLink.label}
				</PrimaryButton>
			}
			photoGalleryTitle={
				<SectionTitle suffix="homepage-photo-galleries-title" link={photoGalleryLink}>
					{pageData.photoGalleriesTitle}
				</SectionTitle>
			}
			photoGalleryNews={photoGalleryNews}
			photoGalleryLink={
				<PrimaryButton suffix="homepage-photo-galleries-link" href={photoGalleryLink.href}>
					{photoGalleryLink.label}
				</PrimaryButton>
			}
			horizontalAdvertisement={
				horizontalAdvertisementItems.length > 0 && (
					<Advertisement items={horizontalAdvertisementItems} orientation={OrientationTypes.horizontal} />
				)
			}
			categories={extraCategories}
			head={
				<NextSeo
					title={pageData.seoTitle || pageData.title}
					description={pageData.searchDescription || seoData.description}
					openGraph={{
						title: pageData.seoTitle || pageData.title,
						description: pageData.searchDescription || seoData.description,
						url: pageData.fullUrl || '',
						type: 'website',
						images: [
							{
								url: seoData.sharableImage.rendition?.url || '',
								alt: seoData.title,
							},
						],
					}}
					twitter={{
						cardType: 'summary_large_image',
					}}
					{...(tags.length > 0 && {
						additionalMetaTags: [
							{
								name: 'keywords',
								content: tags.map(item => item.name).join(', '),
							},
						],
					})}
				/>
			}
		/>
	)
}

export default Homepage
